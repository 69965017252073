<template>
  <div class="warp">
    <div class="left_check_warp">
      <TopCard ref="TopCardRef"
               text="自由选题" />
      <a-form :model="form"
              ref="formRef"
              :rules="rules"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol">
        <a-row :gutter="10">
          <a-col :span="24">
            <a-form-item label="选择科目："
                         :label-col="{span:3}"
                         :wrapper-col="{span:20}">
              <div class="subject_warp">
                <span v-for="item in subjectList"
                      :class="{'subject_item':true,'isActive':form.subject_id == item.subject_id}"
                      @click="checkSubject(item.subject_id)"
                      :key="item.subject_id">{{item.subject_name}}</span>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="选择地区："
                         name="paper_district">
              <a-select style="width:100%"
                        v-model:value="form.paper_district"
                        :open='false'
                        @click.prevent="show('dir')"
                        placeholder="请选择地区"
                        :options='paperDir.map(item=>({label:item.paper_district_name,value:item.paper_district_id}))'>

              </a-select>
            </a-form-item>

          </a-col>

          <a-col :span="12">
            <a-form-item label="题目来源："
                         name="sourceId">
              <a-select v-model:value="form.sourceId"
                        style="width:100%"
                        :open='false'
                        @click="show('source')"
                        mode='multiple'
                        placeholder="请选择题目来源">
                <a-select-option v-for="item in sourceList"
                                 :key="item.id"
                                 :value="item.id"
                                 disabled>
                  {{item.text}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="开始年份："
                         name="startYear">
              <a-select style="width:100%"
                        v-model:value="form.startYear"
                        placeholder="请选择开始年份"
                        :options='year.map(item=>({label:item,value:item}))'>

              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="截止年份："
                         name="endYear">
              <a-select style="width:100%"
                        v-model:value="form.endYear"
                        placeholder="请选择截止年份">
                <a-select-option v-for="item in year1"
                                 :key="item"
                                 :value="item">{{item}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="题目数量："
                         name="number">
              <a-input placeholder="请输入题目数量"
                       type="number"
                       v-model:value="form.number"
                       style="width:100%" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="题目难度："
                         name="difficulty">
              <div class="row_center">
                <a-select style="width:100%"
                          v-model:value="form.difficulty"
                          @click.prevent="show('difficulty')"
                          mode='multiple'
                          :open='false'
                          placeholder="请选择题目难度">
                  <a-select-option v-for="item in difficultList"
                                   :key="item.id"
                                   :value="item.id"
                                   disabled>
                    {{item.text}}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-item>
          </a-col>

        </a-row>

        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="题目类型："
                         name="type">
              <a-select style="width:100%"
                        v-model:value="form.type"
                        @click.prevent="show('type')"
                        :open='false'
                        mode='multiple'
                        placeholder="请选择题目类型">
                <a-select-option v-for="item in typeList"
                                 :key="item.question_type_id"
                                 :value="item.question_type_id"
                                 disabled>
                  {{item.question_type_name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12"
                 v-show="isShow">
            <!--  地理英语才显示 -->
            <a-form-item label="题目类别："
                         name="category">
              <a-select style="width:100%"
                        v-model:value="form.category"
                        @click.prevent="show('category')"
                        :open='false'
                        mode='multiple'
                        placeholder="请选择题目类别">
                <a-select-option v-for="item in categoryList"
                                 :key="item.question_category_id"
                                 :value="item.question_category_id"
                                 disabled>
                  {{item.question_category_name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="line"></div>
        <div class="point_warp">
          知识点：
        </div>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="一级条目："
                         name="point">
              <a-select style="width:100%"
                        v-model:value="form.point"
                        :open='false'
                        mode='multiple'
                        @click.prevent="show('point')"
                        placeholder="请选择一级条目">
                <a-select-option v-for="item in pointList"
                                 :key="item.knowledge_id"
                                 :value="item.knowledge_id"
                                 disabled>
                  {{item.knowledge_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12"
                 v-show="showLeavel2.length != 0">
            <a-form-item label="二级条目："
                         name="point2">
              <a-select style="width:100%"
                        v-model:value="form.point2"
                        mode='multiple'
                        :open='false'
                        @click.prevent="checkPoint2"
                        placeholder="请选择二级条目">
                <a-select-option v-for="item in showLeavel2"
                                 :key="item.knowledge_id"
                                 :value="item.knowledge_id"
                                 disabled>
                  {{item.knowledge_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10"
               v-show="showLeavel3.length != 0">
          <a-col :span="12">
            <a-form-item label="三级条目："
                         name="point3">
              <a-select style="width:100%"
                        v-model:value="form.point3"
                        :open='false'
                        mode='multiple'
                        @click.prevent="checkPoint3"
                        placeholder="请选择三级条目">
                <a-select-option v-for="item in showLeavel3"
                                 :key="item.knowledge_id"
                                 :value="item.knowledge_id"
                                 disabled>
                  {{item.knowledge_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="line"></div>
        <div class="point_warp">
          章节：
        </div>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-item label="一级条目："
                         name="chapter">
              <a-select style="width:100%"
                        v-model:value="form.chapter"
                        @click.prevent="show('chapter')"
                        :open='false'
                        mode='multiple'
                        placeholder="请选择一级条目">
                <a-select-option v-for="item in chapter1"
                                 :key="item.chapter_id"
                                 :value="item.chapter_id"
                                 disabled>
                  {{item.chapter_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="二级条目："
                         v-show="chapterList2.length != 0"
                         name="chapterId2">
              <a-select style="width:100%"
                        v-model:value="form.chapterId2"
                        @click.prevent="checkChapter2"
                        :open='false'
                        mode='multiple'
                        placeholder="请选择二级条目">
                <a-select-option v-for="item in chapterList2"
                                 :key="item.chapter_id"
                                 :value="item.chapter_id"
                                 disabled>
                  {{item.chapter_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">

          <a-col :span="12"
                 v-show="chapterList3.length != 0">
            <a-form-item label="三级条目："
                         name="chapterId3">
              <a-select style="width:100%"
                        v-model:value="form.chapterId3"
                        :open='false'
                        mode='multiple'
                        @click.prevent="checkChapter3"
                        placeholder="请选择三级条目">
                <a-select-option v-for="item in chapterList3"
                                 :key="item.chapter_id"
                                 :value="item.chapter_id"
                                 disabled>
                  {{item.chapter_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="四级条目："
                         v-show="chapterList4.length != 0"
                         name="chapterId4">
              <a-select style="width:100%"
                        v-model:value="form.chapterId4"
                        mode='multiple'
                        @click.prevent="checkChapter4"
                        placeholder="请选择四级条目">
                <a-select-option v-for="item in chapterList4"
                                 :key="item.chapter_id"
                                 :value="item.chapter_id"
                                 disabled>
                  {{item.chapter_desc}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
      <p style=""
         class='exbtn'
         @click="handleShow">
        <img src="@/static/yiwen.png"
             alt="">
        说明
      </p>
      <div class="explain"
           v-if="ecplain">
        <p>1.请按顺序选择科目、地区、题目来源、年份、题目数量；</p>
        <p>2.广东卷指广州、深圳以外城市统考卷； </p>
        <p>3.英语可以按题目类别筛选； </p>
        <p>4.题目年份指学年结束的时间，例如2021年9月的试题属于2021-2022学年，因此年份是2022年。 </p>
      </div>
      <div class="submit"
           @click="submit">
        提 交
      </div>
    </div>
    <div class="right_point_warp">
      <div class="dir_warp"
           v-show="right_is_active == 'dir'">
        <div :class="{'check_item':true,'is_check':item.paper_district_id == form.paper_district}"
             v-for="item in paperDir"
             @click="checkItem(item.paper_district_id,'paper_district')"
             :key="item.paper_district_id">
          {{item.paper_district_name}}
        </div>
      </div>

      <div class="source_warp"
           v-show="right_is_active == 'source'">
        <!-- <div :class="{'check_item':true,'is_check':item.id == form.sourceId}" -->
        <div :class="{'check_item':true,'is_check':form.sourceId.findIndex(it=>item.id == it) > -1}"
             v-for="item in sourceList"
             @click="checkItemArr(item.id,'sourceId')"
             :key="item.id">
          {{item.text}}
        </div>
      </div>

      <div class="difficulty_warp"
           v-show="right_is_active == 'difficulty'">
        <div :class="{'check_item':true,'is_check':form.difficulty.findIndex(it=>item.id == it) > -1}"
             v-for="item in difficultList"
             @click="checkItemArr(item.id,'difficulty')"
             :key="item.id">
          {{item.text}}
        </div>
      </div>

      <div class="type_warp"
           v-show="right_is_active == 'type'">
        <div :class="{'check_item':true,'is_check':form.type.findIndex(it=>item.question_type_id == it) > -1}"
             v-for="item in typeList"
             @click="checkItemArr(item.question_type_id,'type')"
             :key="item.question_type_id">
          {{item.question_type_name}}
        </div>
      </div>

      <div class="category"
           v-show="right_is_active == 'category'">
        <div :class="{'check_item':true,'is_check':form.category.findIndex(it=>item.question_category_id == it) > -1}"
             v-for="item in categoryList"
             @click="checkItemArr(item.question_category_id,'category')"
             :key="item.question_category_id">
          {{item.question_category_name}}
        </div>
      </div>

      <!-- 多选区域 -->
      <!-- 知识点一级条目 -->
      <div class="point_warp"
           v-show="right_is_active == 'point'">
        <div v-if='pointList.length<=0'>暂无数据</div>
        <div :class="{'check_item':true,'is_check':form.point.findIndex(it=>item.knowledge_id == it) > -1}"
             v-for="item in pointList"
             @click="checkItemArr(item.knowledge_id,'point')"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 知识点二级条目 -->
      <div class="point_warp"
           v-show="right_is_active == 'point2'">
        <div v-for="item in showLeavel2"
             :class="{'check_item':true,'is_check':form.point2.indexOf(item.knowledge_id) > -1}"
             @click="checkItemArr(item.knowledge_id,'point2')"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 知识点三级条目 -->
      <div class="point_warp"
           v-show="right_is_active == 'point3'">
        <div :class="{'check_item':true,
        'is_check':form.point3.findIndex(it=>item.knowledge_id == it) > -1}"
             v-for="(item,index) in showLeavel3"
             @click="checkItemArr(item.knowledge_id,'point3')"
             :key="index">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 章节 一级条目 -->
      <div class="chapter_warp"
           v-show="right_is_active == 'chapter'">
        <div v-if='chapter1.length<=0'>暂无数据</div>
        <div :class="{'check_item':true,
        'is_check':form.chapter.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="item in chapter1"
             @click="checkItemArr(item.chapter_id,'chapter')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

      <!-- 章节 二级条目 -->
      <div class="chapter_warp"
           v-show="right_is_active == 'chapter2'">
        <div :class="{'check_item':true,
        'is_check':form.chapterId2.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="item in chapterList2"
             @click="checkItemArr(item.chapter_id,'chapterId2')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

      <!-- 章节 三级条目 -->
      <div class="chapter_warp"
           v-show="right_is_active == 'chapter3'">
        <div :class="{'check_item':true,
        'is_check':form.chapterId3.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="item in chapterList3"
             @click="checkItemArr(item.chapter_id,'chapterId3')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

      <!-- 章节 四级条目 -->
      <div class="chapter_warp"
           v-show="right_is_active == 'chapter4'">
        <div :class="{'check_item':true,
        'is_check':form.chapterId4.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="item in chapterList4"
             @click="checkItemArr(item.chapter_id,'chapterId4')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

    </div>
    <!-- <rightMenu :dataList='dataList'
               :right_is_active='right_is_active' /> -->
  </div>
</template>

<script setup>
import { instance } from '@/utils/http.js'
import { reactive } from '@vue/reactivity'
import { computed, ref, watch, onMounted, toRefs } from '@vue/runtime-core'
import { rules, years, formItemLayout } from './property'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getUserSubject } from '@/api/subject.js'
import { getPaperDirList, getQuestionDifficulty, getPaperSource, getTypeCategory, getChapterList, getFreeTrain } from "@/api/freedomTopic.js"
import { getKnowledgeList } from '@/api/knowledge.js'
import TopCard from '@/views/components/top_card/index.vue'
//import rightMenu from './components/rightMenu.vue'


let router = useRouter()
let store = useStore()

let subjectList = ref([])
let paperDir = ref([])
let sourceList = ref([])
let typeList = ref([])
let categoryList = ref([])
let categoryListFlat = ref([])
let difficultList = ref([])
// 知识点列表
let pointList = ref([])
let form = reactive({
  sourceId: [],
  subject_id: '',
  paper_district: '',
  startYear: 2021,
  endYear: 2022,
  number: '',
  type: [],
  category: [],
  difficulty: [],
  point: [],
  point2: [],
  point3: [],
  chapterId4: [],
  chapterId3: [],
  chapterId2: [],
  chapter: [],
})
let ecplain = ref(false)
let right_is_active = ref('dir')
/* let list = reactive({
  dataList: {
    subjectList,
    paperDir,
  }
})
let { dataList } = toRefs(list) */

//章节列表
let chapter1 = ref([])
let chapter2 = ref([])
let chapter3 = ref([])
let chapter4 = ref([])
let chapterListFlat = ref([])

let formRef = ref()

subjectList.value = store.state.subject.subjectList
//开始年份的最大选项只能比当前年份大1
let year = computed(() => {
  let nowYear = new Date().getFullYear()
  let arr = years.filter(item => {
    return item <= nowYear + 1
  })
  return arr
})
//结束年份的最大选项只能比当前年份大1，最小为开始年份
let year1 = computed(() => {
  let nowYear = new Date().getFullYear()
  let arr = years.filter(item => {
    return item <= nowYear + 1 && item >= form.startYear
  })
  return arr
})
//当为英语科目时显示 ‘题目类别’ 选项
let isShow = computed(() => {
  if (subjectList.value.length == 0) {
    return false
  }
  if (form.subject_id) {
    var subject = subjectList.value.find(item => item.subject_id == form.subject_id)
    // if (subject.subject_name == '地理' || subject.subject_name == '英语') {
    if (subject.subject_name == '英语') {
      return true
    }
  }

  return false
})
//知识点 二级
let showLeavel2 = computed(() => {
  let arr = []
  categoryListFlat.value.forEach(item => {
    if (form.point.indexOf(item.knowledge_id) != -1) {
      arr = arr.concat(item.child)
    }
  });
  return arr
})
let showLeavel3 = computed(() => {
  let arr = []
  categoryListFlat.value.forEach(item => {
    let idm = form.point2.indexOf(item.knowledge_id)
    if (idm != -1 && item.child) {
      arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
    }
  });
  return arr
})
//章节 二级
let chapterList2 = computed(() => {
  let arr = []
  chapterListFlat.value.forEach(item => {
    let idm = form.chapter.indexOf(item.chapter_id)
    if (idm != -1 && item.child) {
      arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
    }
  });
  return arr
})
//章节 三级
let chapterList3 = computed(() => {
  let arr = []
  chapterListFlat.value.forEach(item => {
    let idm = form.chapterId2.indexOf(item.chapter_id)
    if (idm != -1 && item.child) {
      arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
    }
  });
  return arr
})
//章节四级
let chapterList4 = computed(() => {
  let arr = []
  chapterListFlat.value.forEach(item => {
    let idm = form.chapterId3.indexOf(item.chapter_id)
    if (idm != -1 && item.child) {
      arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
    }
  });
  return arr
})
//知识点一级条目变动
watch(() => form.point, () => {
  form.point2 = []
}, { deep: true })

//知识点二级条目变动
watch(() => form.point2, () => {
  form.point3 = []
}, { deep: true })

//章节一级条目变动
watch(() => form.chapter, () => {
  form.chapterId2 = []
}, { deep: true })

//章节二级条目变动
watch(() => form.chapterId2, () => {
  form.chapterId3 = []
}, { deep: true })

//章节三级条目变动
watch(() => form.chapterId3, () => {
  form.chapterId4 = []
}, { deep: true })

//初始化时获取科目列表
let getSubjectList = async () => {
  if (!subjectList.value.length) {
    const { data } = await getUserSubject()
    subjectList.value = data.list
    store.commit('subject/setSubjectList', data.list)
  }
  form.subject_id = subjectList.value[0].subject_id
  getDirList()
  getChapter()
  getKnowledge()
  getCateAndtype()
}

//切换科目
const checkSubject = (id) => {
  if (form.subject_id == id) return
  form.subject_id = id
  formRef.value.resetFields()
  getDirList()
  getCateAndtype()
  getKnowledge()
  getChapter()

  // 切换科目需要清空所关联的下拉框
  form.category = []
  form.type = []
  right_is_active.value = 'dir' //右侧显示选择地区栏
  form.difficulty = []
}

// 选择地区试卷
const getDirList = async () => {
  const { data } = await getPaperDirList({ subject_id: form.subject_id })
  paperDir.value = data.list
  form.paper_district = data.list[0].paper_district_id
  getSourceList()
}

//获取题目来源列表
const getSourceList = async () => {
  const { data } = await getPaperSource({
    subject_id: form.subject_id,
    paper_district: form.paper_district
  })
  data.list.unshift({ text: '所有', id: '' })
  sourceList.value = data.list
}

//获取题目难度列表
let getDefficultList = async () => {
  const { data } = await getQuestionDifficulty()
  difficultList.value = data
}
//题目类型和题目类别
const getCateAndtype = async () => {
  const { data } = await getTypeCategory({
    subject_id: form.subject_id
  })
  typeList.value = data.list.question_type
  categoryList.value = data.list.question_category
}
// 数组扁平化
const flatList = (list) => {
  let allList = []
  for (let i = 0, len = list.length; i < len; i++) {
    allList.push(list[i])
    if (list[i].child && list[i].child.length > 0) {
      allList = allList.concat(flatList(list[i].child))
    }
  }
  return allList
}
//获取知识点列表
const getKnowledge = async () => {
  const { data } = await getKnowledgeList({
    subject_id: form.subject_id
  })
  pointList.value = data.list
  categoryListFlat.value = flatList(data.list)
}

//获取章节列表
const getChapter = async () => {
  const { data } = await getChapterList({
    subject_id: form.subject_id
  })
  chapter1.value = data.list
  chapterListFlat.value = flatList(data.list)

}

//获取二级考点
const checkPoint2 = () => {
  if (!form.point.length) {
    return
  }
  right_is_active.value = 'point2'
}
//三级知识点
const checkPoint3 = () => {
  if (!form.point2.length) {
    return
  }
  right_is_active.value = 'point3'
}
//二级章节
const checkChapter2 = () => {
  if (!form.chapter.length) {
    return
  }
  right_is_active.value = 'chapter2'
}
//三级章节
const checkChapter3 = () => {
  if (!form.chapterId2.length) {
    return
  }
  right_is_active.value = 'chapter3'
}
//四级章节
const checkChapter4 = () => {
  if (!form.chapterId3.length) {
    return
  }
  right_is_active.value = 'chapter4'
}
//显示右侧选项栏
const show = (str) => {
  right_is_active.value = str
}
// 右侧为多选的时候会触发这个方法
const checkItemArr = (id, name) => {
  let index = form[name].indexOf(id)
  if (index != -1) {
    form[name].splice(index, 1)
  }
  //  else if (this.form[name].length == 5) {
  //   return
  // }
  else {
    form[name].push(id)
  }
}
const checkItem = (id, name) => {
  form[name] = id
  //科目变，地区变
  // 地区改变的时候，获取来源，
  if (right_is_active.value == 'dir') {
    form.sourceId = []
    getSourceList()
  }
}
const submit = async () => {
  //console.log(form)
  let knowledge_ids = form.point3
  if (knowledge_ids.length == 0) {
    if (form.point2.length == 0) {
      knowledge_ids = form.point
    } else {
      knowledge_ids = form.point2
    }
  }
  let chapter_ids = form.chapterId4
  if (chapter_ids.length == 0) {
    chapter_ids = form.chapterId3
    if (chapter_ids.length == 0) {
      chapter_ids = form.chapterId2
      if (chapter_ids.length == 0) {
        chapter_ids = form.chapter
      }
    }
  }

  formRef.value.validate().then(async (val) => {
    if (!val) return
    let form1 = {
      app_id: 4,
      questionSubject: form.subject_id,
      questionDistrict: form.paper_district,
      source_id: form.sourceId.join(','),
      questionType: form.type,
      questionCategory: form.category,
      questionYearBegin: form.startYear,
      questionYearEnd: form.endYear,
      knowledge_ids,
      chapter_ids,
      questionNumber: Number(form.number),
      question_difficulty: form.difficulty
    }
    //提交表单获取试卷id
    const { data, status } = await getFreeTrain(form1)
    window.sessionStorage.removeItem('paper')
    window.sessionStorage.removeItem('images')
    window.sessionStorage.removeItem('testpaperCardIndex')
    if (status == 200) {
      message.success('成功')
      router.push('/doPaper?isFrreeDom=1&user_paper_id=' + data.list.user_paper_id)
    }

  })

}
const handleShow = () => {
  ecplain.value = !ecplain.value
}

onMounted(() => {
  getSubjectList()
  getDefficultList()
})

</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  padding-left: 10rem;
  width: calc(100% - 10rem);
  height: 100vh;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  font-size: 30rem;
  .left_check_warp {
    flex: 1;
    margin-top: 18rem;
    margin-right: 15rem;
    overflow-x: hidden !important;
    .subject_warp {
      overflow-x: scroll;
      white-space: nowrap;
      &::-webkit-scrollbar-thumb {
        background-color: #69696952;
      }
      .subject_item {
        display: inline-block;
        padding: 0 43rem;
        //width: 150rem;
        height: 64rem;
        line-height: 64rem;
        margin-right: 30rem;
        text-align: center;
        border-radius: 10rem;
        color: #333333;
        font-weight: 500;
        font-size: 30rem;
        cursor: pointer;
        background: rgba(246, 246, 246, 1);
      }
    }
    .point_warp {
      font-weight: bold;
      margin-top: 30rem;
      color: #2196f3;
      width: 128rem;
      text-align: left;
      margin-left: 50rem;
      font-size: 30rem;
    }
    .explain {
      background: #f6f6f6;
      font-size: 24rem;
      margin: 10rem 23rem 0rem 49rem;
      padding: 14rem 0 14rem 20rem;
      font-weight: 500;
      line-height: 1.8em;
      transition: all 0.3s;
    }
    .submit {
      width: calc(50% - 80rem);
      height: 80rem;
      line-height: 80rem;
      background: #2196f3;
      text-align: center;
      margin: 30rem 0 124rem 52rem;
      font-weight: bold;
      color: #ffffff;
      font-size: 32rem;
      border-radius: 10rem;
    }
  }
  .right_point_warp {
    height: 100vh;
    width: 580rem;
    background: rgba(235, 243, 255, 1);
    height: 100%;
    font-size: 30rem;
    overflow-y: scroll;
    line-height: 40rem;
    .check_item {
      background: #ebf3ff;
      padding: 20rem 30rem;
      height: 80rem;
      color: #343434;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      &:hover {
        height: auto;
        white-space: normal;
        overflow: visible;
        color: #2196f3;
      }
    }
  }
}
.line {
  width: 99%;
  height: 1px;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
  margin-bottom: 30rem;
  margin-left: 15rem;
}
.isActive {
  background: #2196f3 !important;
  color: #ffffff !important;
  font-weight: 700 !important ;
}

.is_check {
  background: rgba(108, 185, 245, 1) !important;
  font-weight: 500;
  color: white !important;
}
// 说明按钮
.exbtn {
  color: #ff6147;
  text-indent: 50rem;
  font-size: 28rem;
  margin-bottom: 0;
  cursor: pointer;
  display: inline-block;
  img {
    width: 26rem;
    height: 26rem;
    margin-top: -4rem;
  }
}

.row_center {
  /* display: flex;
  justify-content: center;
  height: 54rem;
  overflow: hidden; */
}
:deep .ant-form-item-label > label {
  font-size: 30rem;
  height: 64rem;
}
:deep
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-size: 30rem;
  margin-right: 5rem;
}
:deep .ant-select {
  font-size: 30rem;
}
:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 64rem;
  padding: 0 20rem;
}
:deep
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  height: 64rem;
  overflow: hidden;
}
:deep .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 64rem;
}
:deep .ant-select-multiple .ant-select-selection-item {
  height: 50rem;
  line-height: 50rem;
}
:deep .ant-select-selection-overflow {
  flex-wrap: nowrap;
}
:deep .ant-input {
  font-size: 30rem;
  height: 64rem;
  padding: 0 20rem;
}
</style>